import React from 'react';
import HeaderCallAction from './headerCallAction'
import Header from './header'
import Footer from './footer'
import Helmet from 'react-helmet'
import { Global, css } from '@emotion/react';
// import tagManager from "../scripts/tag-manager";
const Layout = (props) => {
    return (
        <React.Fragment>
            <Global
                styles={css`
                    html {
                        font-size: 50%;
                        box-sizing:border-box;
                        scroll-behavior: smooth;
                        @media(min-width:768px){
                            font-size: 62.5%;
                        }
                        @media(min-width:992px){
                            font-size: 70%;
                        }
                        @media(min-width:1200px){
                            font-size: 75%;
                        }
                    }
                    *,*:before, *:after{
                        box-sizing:inherit;
                    }
                    body {
                        font-size: 18px;
                        font-size: 1.6rem;
                        line-height: 1.5;
                        font-family:'Montserrat', sans-serif;
                        background-color:#FFF;
                        color:#164688;
                        margin:0;
                        overflow-x: hidden;
                    }
                    ::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    ::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: #0024B6;
                    }
                    @-moz-document url-prefix() {
                        .scroller {
                            scrollbar-color: #0024B6 #0024B6;
                            scrollbar-width: thin;
                        }
                    }
                    h1,h2,h3{
                        margin:0;
                        line-height:1.5;
                    }
                    p{
                        font-size:2.3rem;
                        @media(min-width:1200px){
                            font-size: 1.8rem;
                        }
                    }
                    .slick-next{
                        right: 25px !important;
                        z-index:10000
                    }
                     .slick-prev{
                        left: 25px !important;
                        z-index:10000
                    }
                    .slick-next:before, .slick-prev:before {
                        color: #FFBE85 !important;
                    }
                    footer img{
                        max-width:400px!important;
                        height:auto!important;
                    }

                    .slick-slide div div:focus{
                        outline:none !important;
                    }
                    `}
            />
            <Helmet>
                <title>Rayodent | {props.page ? props.page : ""}</title>

                <link rel="preload" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.css" as="style" />
                <link rel="preload" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" as="style" />
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

                {/* <script dangerouslySetInnerHTML={{ __html: tagManager }} /> */}

                <script src="https://smtpjs.com/v3/smtp.js"></script>

                <script type="text/javascript">

                    {`(function () { var ldk = document.createElement('script'); ldk.type = 'text/javascript'; ldk.async = true; ldk.src = 'https://s.cliengo.com/weboptimizer/5b4a1e7ae4b0028694d5d7ec/607fbfc9ea72fd002a2328a0.js?platform=view_installation_code'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ldk, s); })();`}

                </script>
                <body className="scroller" />
            </Helmet>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQFCV43"
                height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
            <HeaderCallAction />
            <Header />
            {props.children}
            <Footer />
        </React.Fragment>
    );
}

export default Layout