import * as React from "react"
import Layout from '../components/layout'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { StaticImage } from "gatsby-plugin-image"

const Enlace = styled(Link)`
    margin:auto;
    text-decoration:none;
    margin-top:1rem;
    display: block;
    width:100%;
    padding: 2rem;
    border: 1px;
    border-radius: 2rem;
    background-color:#FFBD86;
    color: #6E5139;
    text-transform:uppercase;
    font-weight:700;
    -webkit-appearance: none;
    @media(min-width:768px){
        max-width:calc(50% - 1.5rem);
    }
    :hover{
        cursor:pointer;
        background-color:#FFAF6D;
        transition: background-color .15s ease-in-out;
    }
    :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem #6E5139;
        transition: box-shadow .15s ease-in-out;
        background-color:#FFAF6D;
    }
`


const CommentSent = () => {

    //     const { image, message } = useStaticQuery(graphql`
    // {
    //     image: file(relativePath: { eq:"dentist.jpg" } ) {
    //         sharp:childImageSharp {
    //             fluid(quality: 100) {
    //                 ...GatsbyImageSharpFluid
    //             }
    //         }
    //       }
    //     message: allStrapiMessages {
    //         nodes {
    //           experienceParagraph
    //         }
    //       }
    // }
    // `)

    return (
        <Layout page="Comentario enviado">
            <div css={css`
                    padding-top:5rem;
                    @media (min-width:1200px){
                        display:grid;
                        align-items:center;
                        grid-template-columns: 1fr 1fr;
                        column-gap:5rem;
                        min-height:70vh;
                    }
                `}>
                <div css={css`
                        padding:0 5rem;
                        text-align:center;
                `}>
                    <h1 css={css`text-align:center;`}>Gracias por su apoyo.</h1>
                    <p css={css`text-align:center;`}>Estamos muy agradecidos por tomarse su tiempo y escribir una referencia para nosotros.</p>
                    <Enlace to='/'>Regresar</Enlace>
                </div>
                <div css={css`
                        @media (max-width:1200px){
                            display:none;
                        }
                `}>
                    <StaticImage src="../images/dentist.jpg"
                        css={css`
                        max-width:100%;
                        min-width:100%;
                    `} />
                </div>
            </div>
        </Layout>
    )
}

export default CommentSent